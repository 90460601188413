/* eslint-disable max-len */
import router, { useRouter } from 'next/router';
import {
    PROXY_BASKET_URL,
    PROXY_CONTENT_URL,
    PROXY_NAVIGATION_URL,
    PROXY_ORDERS_URL,
    PROXY_PRODUCTS_URL,
    PROXY_USERS_URL,
    PROXY_VEHICLES_URL,
    SPA_URL,
} from '~/constants/api';
import { isServer } from '~/helpers';

// Only on server side !!!
export const authEndpoints = {
    userProfile: `https://${process.env.AUTH_DOMAIN}/connect/userinfo`,
};

// New endpoints
export const endpoints = {
    // https://app-ftz-bff-dev.azurewebsites.net/swagger/index.html
    content: {
        megaMenu: `${PROXY_CONTENT_URL}/megaMenu`,
        features: `${PROXY_CONTENT_URL}/Features`,
    },
    routing: {
        // https://app-ftz-routing-dev.azurewebsites.net/swagger/index.html
        breadcrumbs: `${PROXY_NAVIGATION_URL}/breadcrumb/{{locale}}`,
    },
    orders: {
        // https://app-ftz-orders-dev.azurewebsites.net/swagger
        order: `${PROXY_ORDERS_URL}/{{locale}}/order`,
        getManualWebOrders: `${PROXY_ORDERS_URL}/{{locale}}/order/manualprocessing`,
        getManualWebOrdersCount: `${PROXY_ORDERS_URL}/{{locale}}/order/countManualOrders`,
        getDepartments: `${PROXY_ORDERS_URL}/{{locale}}/order/manualprocessing/departments`,
        getChemicalProducts: `${PROXY_ORDERS_URL}/{{locale}}/ChemicalProducts/chemical-products`,
        invoices: `${PROXY_ORDERS_URL}/{{locale}}/invoices`,
        credits: `${PROXY_ORDERS_URL}/{{locale}}/credits`,
        rma: `${PROXY_ORDERS_URL}/{{locale}}/rma`,
        externalCredits: `${PROXY_ORDERS_URL}/ExternalCredits`,
        getLatestInternalOrders: `${PROXY_ORDERS_URL}/{{locale}}/Order/latest-internal-orders`,
    },
    users: {
        // https://app-ftz-users-dev.azurewebsites.net/swagger/index.html
        externalLogins: `${PROXY_USERS_URL}/me/external-logins`,
        nfData: `${PROXY_USERS_URL}/me/external-logins/nfdata`,
        haynes: `${PROXY_USERS_URL}/me/external-logins/haynes`,
        users: `${PROXY_USERS_URL}/users`,
        usersByCustomerQuery: `${PROXY_USERS_URL}/users/by-customer-query`,
        meInfo: `${PROXY_USERS_URL}/me/info`,
        migration: `${PROXY_USERS_URL}/migration`,
        externalSubUsers: `${PROXY_USERS_URL}/externalSubUsers`,
        externalSubUsersList: `${PROXY_USERS_URL}/me/{{locale}}/externalSubUsers`,
        roles: `${PROXY_USERS_URL}/roles/{{locale}}`,
        permissions: `${PROXY_USERS_URL}/permissions/{{locale}}`,
        latestCustomers: `${PROXY_USERS_URL}/me/latestCustomers`,
        logImpersonation: `${PROXY_USERS_URL}/me/logImpersonation`,
        departments: `${PROXY_USERS_URL}/departments`,
        signUpNewsletter: `${PROXY_USERS_URL}/me/newsletter-signup`,
        changePassword: `${PROXY_USERS_URL}/me/password`,
        changeEmail: `${PROXY_USERS_URL}/me/email`,
    },
    userMigration: {
        // https://app-ftz-users-dev.azurewebsites.net/swagger/index.html
        // verifyToken: `${PROXY_USERS_URL}/migration/verify-token`,
        // creatAccount: `${PROXY_USERS_URL}/migration/create-account/`
    },
    products: {
        // https://app-ftz-products-dev.azurewebsites.net/swagger
        getProductDetails: `${PROXY_PRODUCTS_URL}/{{locale}}/product/productId`,
        getProductVariantSimple: `${PROXY_PRODUCTS_URL}/{{locale}}/product/variant-simple`,
        getVariantDocuments: `${PROXY_PRODUCTS_URL}/{{locale}}/product/variant/documents`,
        getVariantDocumentsBatch: `${PROXY_PRODUCTS_URL}/{{locale}}/product/variant/documents/batch`,
        getVariantsSimple: `${PROXY_PRODUCTS_URL}/{{locale}}/product/variants-simple`,
        getVariantsSimpleFtz: `${PROXY_PRODUCTS_URL}/{{locale}}/Product/variants-simple-by-ftzCode`,
        getVariantPrices: `${PROXY_PRODUCTS_URL}/{{locale}}/price/variants/prices`,
        getVariantBatch: `${PROXY_PRODUCTS_URL}/{{locale}}/Product/variants/batch`,
        getStockStatus: `${PROXY_PRODUCTS_URL}/{{locale}}/Stock/variants`,
        getStockStatusDetails: `${PROXY_PRODUCTS_URL}/{{locale}}/stock/variants/details`,
        getStockStatusExternal: `${PROXY_PRODUCTS_URL}/{{locale}}/stock/variants/externalStock`,
        vehicles: `${PROXY_PRODUCTS_URL}/{{locale}}/product/cars`,

        price: `${PROXY_PRODUCTS_URL}/{{locale}}/price`,

        getProductList: `${PROXY_PRODUCTS_URL}/ProductList`,
        getSearchResultsList: `${PROXY_PRODUCTS_URL}/search/products`,
        salesPriceMarkup: `${PROXY_PRODUCTS_URL}/{{locale}}/SalesPriceMarkup`,

        universalPartCategory: `${PROXY_PRODUCTS_URL}/{{locale}}/universalpartcategory`,
        sparePartCategory: `${PROXY_PRODUCTS_URL}/{{locale}}/sparepartcategory`,

        pictogram: `${PROXY_PRODUCTS_URL}/{{locale}}/pictogram/pictogram`,
        getVariantInfo: `${PROXY_PRODUCTS_URL}/{{locale}}/product/infos/variant/withFTZcode`,
        getProductVehicleInfoByIds: `${PROXY_PRODUCTS_URL}/{{locale}}/Product/infos/variant/by-product-ids`,
        getProductsVehicleInfo: `${PROXY_PRODUCTS_URL}/{{locale}}/product/infostext`,
        getVariantVehicleInfo: `${PROXY_PRODUCTS_URL}/{{locale}}/product/variant/infostext`,
        getVariantVehicleOilInfo: `${PROXY_PRODUCTS_URL}/{{locale}}/product/variant/infostext`,
        quickSearch: `${PROXY_PRODUCTS_URL}/search/quick-search`,
        errorReport: `${PROXY_PRODUCTS_URL}/IssueReporting/report`,
        truckRequest: `${PROXY_PRODUCTS_URL}/IssueReporting/report/truck`,
        oeRequestPost: `${PROXY_PRODUCTS_URL}/{{locale}}/OeSpareParts/request-form`,
        favoriteProducts: `${PROXY_PRODUCTS_URL}/{{locale}}/FavoriteProducts`,
        deposits: `${PROXY_PRODUCTS_URL}/{{locale}}/Product/deposits`,
        oeMatchesVariants: `${PROXY_PRODUCTS_URL}/{{locale}}/Product/variant/oe-references`,
        oeMatchesProducts: `${PROXY_PRODUCTS_URL}/{{locale}}/Product/oe-matches-from-variants`,
        tireCalculation: `${PROXY_PRODUCTS_URL}/{{locale}}/Product/tire-calculation`,
        itemsFit: `${PROXY_PRODUCTS_URL}/{{locale}}/Product/product-fits`,

        // Label
        submitLabelOrder: `${PROXY_PRODUCTS_URL}/LabelOrdering/OrderLabels`,
    },
    baskets: {
        // https://app-ftz-basket-dev.azurewebsites.net/swagger
        get: `${PROXY_BASKET_URL}/basket/get`,
        create: `${PROXY_BASKET_URL}/basket/create`,
        getNewBasketForExternalBasket: `${PROXY_BASKET_URL}/basket/GetNewBasketForExternalBasket`,
        update: `${PROXY_BASKET_URL}/basket/setoradditem`,
        updateBatch: `${PROXY_BASKET_URL}/Basket/AddItems`,
        delete: `${PROXY_BASKET_URL}/basket/deletebasket`,
        deleteBatch: `${PROXY_BASKET_URL}/basket/deletebasketsbatch`,
        deleteCarGroup: `${PROXY_BASKET_URL}/Basket/deletecargroup`,
        submit: `${PROXY_BASKET_URL}/checkout/completecheckout`,
        changeBasketOwnership: `${PROXY_BASKET_URL}/basket/changebasketownership`,
        setDeliveryOption: `${PROXY_BASKET_URL}/basket/setdeliveryoption`,
        getDeliveryOptions: `${PROXY_BASKET_URL}/checkout/getAllDeliveryOptions`,
        getDeliveryAddresses: `${PROXY_BASKET_URL}/checkout/getdeliveryaddresses`,
        setDeliveryAddress: `${PROXY_BASKET_URL}/basket/setdeliveryaddress`,
        setDeliveryTime: `${PROXY_BASKET_URL}/basket/setdeliverytime`,
        updateOrderDetails: `${PROXY_BASKET_URL}/basket/updateorderdetails`,
        getSavedBaskets: `${PROXY_BASKET_URL}/basket/GetSavedBaskets`,
        saveBasket: `${PROXY_BASKET_URL}/basket/SaveBasket`,
        unSaveBasket: `${PROXY_BASKET_URL}/basket/UnSaveBasket`,
        getNextDelivery: `${PROXY_BASKET_URL}/checkout/GetNextDelivery`,
        latestCustomersBaskets: `${PROXY_BASKET_URL}/basket/GetLatestInternalSavedBasketsOverview`,
        vantageDashboard: `${SPA_URL}/api/basket/api/Vantage/GoToDashboard`,
        getNextDeliveryForBasket: `${PROXY_BASKET_URL}/Basket/GetNextDeliveryForBasket`,
    },
    vehicles: {
        // https://app-ftz-vehicles-dev.azurewebsites.net/swagger/index.html#/
        getManufacturers: `${PROXY_VEHICLES_URL}/{{locale}}/data/manufacturers`,
        getModelTypes: `${PROXY_VEHICLES_URL}/{{locale}}/data/models`,
        getVehicleInfoByCarId: `${PROXY_VEHICLES_URL}/{{locale}}/data/types`,
        getVehicleInfoByLicensePlateOrVin: `${PROXY_VEHICLES_URL}/{{locale}}/data`,
        searchVehicle: `${PROXY_VEHICLES_URL}/{{locale}}/search/vehicle`,
        productsFits: `${PROXY_VEHICLES_URL}/{{locale}}/data/products-fits`,
        chassisRequest: `${PROXY_VEHICLES_URL}/{{locale}}/ChassisSupport/request-form`,
        latestVehicles: `${PROXY_VEHICLES_URL}/{{locale}}/data/latestVehicles`,
        replaceEngineRequest: `${PROXY_VEHICLES_URL}/{{locale}}/ReplacementEngine/request-form`,
        windShieldChange: `${PROXY_VEHICLES_URL}/{{locale}}/WindshieldChange/request-form`,
        getCarAgeFromDamPrNumber: `${PROXY_VEHICLES_URL}/{{locale}}/data/dam/byNumber`,
        getCarDamPrNumberFromDate: `${PROXY_VEHICLES_URL}/{{locale}}/data/dam/byDate`,
    },
};

type UrlReplacer = (url: string, locale?: string) => string;

export const replaceLocale: UrlReplacer = (url, locale = router.defaultLocale) => url.replace('{{locale}}', locale as string);

export enum LocaleOptions {
    path = 'path',
    query = 'query',
    queryAlt = 'queryAlt',
    omit = 'omit',
}

export interface ICreateUrlParamsQuery {
    [key: string]: string | string[] | boolean | number | Date | undefined | null;
}

export interface ICreateUrlParams {
    endpoint: string;
    localeOption?: LocaleOptions;
    query?: ICreateUrlParamsQuery;
    baseUrl?: string;
}

const determinedBaseUrl = isServer ? process.env.NEXT_BASE_URL : window.location.origin;

type UrlCreator = (params: ICreateUrlParams, locale?: string) => string;

export const createUrl: UrlCreator = (
    { endpoint, localeOption, query, baseUrl = determinedBaseUrl },
    locale = router.locale ?? router.defaultLocale,
) => {
    let formattedEndpoint = endpoint;

    const isLocaleIncluded = locale && localeOption !== LocaleOptions.omit;

    if (isLocaleIncluded && localeOption === LocaleOptions.path) {
        formattedEndpoint = endpoint?.replace('{{locale}}', locale);
    }

    const url = new URL(formattedEndpoint, baseUrl);

    if (isLocaleIncluded && localeOption === LocaleOptions.query) {
        url.searchParams.set('locale', locale);
    }

    if (isLocaleIncluded && localeOption === LocaleOptions.queryAlt) {
        url.searchParams.set('lang', locale);
    }

    if (query) {
        Object.entries(query).forEach(([key, val]) => {
            if (typeof val === 'undefined' || val === null) return;

            if (Array.isArray(val)) {
                // Add repeated keys for array value, e.g. f.RimSize=16&f.RimSize=17
                val.forEach((v) => url.searchParams.append(key, v));
            } else {
                url.searchParams.set(key, String(val));
            }
        });
    }

    return url.toString();
};

export const useEndpoints = () => {
    const { locale, defaultLocale } = useRouter();
    const currentLocale = locale || defaultLocale;

    return {
        ...endpoints,
        createUrl: (args: ICreateUrlParams) => createUrl(args, currentLocale),
        replaceLocale: (url: string) => replaceLocale(url, currentLocale),
    };
};
