import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import { useIsMutating, useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import useUser from '~/libs/use-user';
import { Basket as BasketTypes } from '~/models/basket';
import { Bff } from '~/models/bff';
import { queryKeys } from '~/libs/queries/query-keys';
import { getListNameFromBreadcrumb } from '~/libs/ga4/utils';
import { GA4CartUpdate } from '~/libs/ga4/ga4';
import { getCatalogType } from '~/helpers';
import { useBreadcrumb } from '~/libs/queries/routing';
import { useStoredBasketId } from '~/libs/queries/basket';

export type UpdateBasketItemProps = BasketTypes.IAddItemDto & {
    ftzPrice: number;
    url: string;
    listName?: string;
    promotionId?: string;
    componentId?: string;
    componentName?: string;
    campaignSpot?: string;
    itemName?: string;
};

export type UseUpdateBasketParams = {
    details: UpdateBasketItemProps;
    // The previous state of the basket is needed for tracking.
    basketBeforeUpdate: BasketTypes.IBasketDto;
    skipValidation: boolean;
    departmentId?: number;
    campaignType?: string;
    campaignId?: string;
    campaignSpot?: string;
    raptorSource?: string;
};

export type UseUpdateBasketOptions = UseMutationOptions<BasketTypes.IBasketDto, unknown, UseUpdateBasketParams>;

export function useUpdateBasket(opts?: UseUpdateBasketOptions) {
    const { locale } = useRouter();
    const { user } = useUser();

    const { handleSuccess, handleSettled } = useUpdateBasketHandlers();

    return useMutation<BasketTypes.IBasketDto, unknown, UseUpdateBasketParams>({
        mutationKey: queryKeys.basket.updateBasket,
        mutationFn: ({ details, departmentId, skipValidation }) => {
            return apiClient.auth(user).putJSON(
                createUrl(
                    {
                        endpoint: endpoints.baskets.update,
                        localeOption: LocaleOptions.query,
                        query: { departmentId, skipValidation },
                    },
                    locale,
                ),
                details,
            );
        },
        onSuccess: handleSuccess,
        onSettled: handleSettled,
        ...opts,
    });
}

export function useUpdateBasketHandlers() {
    const queryClient = useQueryClient();

    const { locale } = useRouter();

    const { user } = useUser();
    const { data: breadcrumb } = useBreadcrumb();

    const [, setStoredBasketId] = useStoredBasketId();

    const handleSuccess: UseUpdateBasketOptions['onSuccess'] = (updatedBasket, { details: updatedItem, basketBeforeUpdate }) => {
        if (updatedBasket.newBasketCreatedByInvalidState) {
            // A new basket was created due to an invalid basket state on the server.
            // We overwrite the stored basket id to make sure the new basket is loaded when reloading the page.
            setStoredBasketId(updatedBasket.id);
        }

        const staticContent = queryClient.getQueryData<Bff.IStaticContent>(queryKeys.bff.staticContent(locale, user));
        const listName = updatedItem.listName ?? getListNameFromBreadcrumb(breadcrumb);

        GA4CartUpdate({
            catalogType: getCatalogType(updatedItem.url, staticContent?.urlMappings?.spareParts),
            currency: user?.profile?.currency.toString() ?? '',
            employeeId: user?.profile?.employeeId?.toString(),
            listName: listName,
            previousBasket: basketBeforeUpdate,
            updatedBasket,
            updatedItem,
        });
    };

    const handleSettled: UseUpdateBasketOptions['onSettled'] = async (data, error, { departmentId }) => {
        const basketKey = queryKeys.basket.get._def;
        const deliveryOptionsKey = queryKeys.basket.deliveryOptions(departmentId);

        await queryClient.cancelQueries(basketKey, {
            exact: false,
        });

        await queryClient.invalidateQueries(basketKey, {
            exact: false,
        });

        await queryClient.cancelQueries(deliveryOptionsKey);
        await queryClient.invalidateQueries(deliveryOptionsKey);
    };

    return { handleSuccess, handleSettled };
}

// TODO: This should be combined with other basket mutation states
export function useIsUpdatingBasket() {
    return !!useIsMutating(queryKeys.basket.updateBasket);
}
