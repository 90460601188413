import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import useUser, { useIsImpersonated } from '~/libs/use-user';
import getActiveUser from '~/services/user/getActiveUser';
import { usersKeys } from '../usersKeys';

export default function useActiveUser() {
    const { locale } = useRouter();
    const { isLoggedIn, user } = useUser();
    const isImpersonated = useIsImpersonated();

    const { data, error, isLoading, refetch } = useQuery(
        usersKeys.getActiveUser({ user, locale }, Boolean(isImpersonated)),
        ({ signal }) => getActiveUser({ user, locale, signal }),
        {
            enabled: isLoggedIn,
            keepPreviousData: true,
        },
    );

    const isCustomer = data && user && data?.customer?.customerId !== Number(user?.profile?.customer_id);

    return {
        activeProfile: data,
        isLoading: isLoading,
        isCustomer,
        error,
        mutate: refetch,
    };
}
